<template>
  <div class="widgetContainer bg-white contactAccountTypeForm">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="cancel" />
        <p class="title">
          {{ $t('send_money') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <h4 class="header-2">
        {{ $t('send_money_via') }}
      </h4>
      <div class="listWithActionsv2 border-on">
        <ul>
          <li
            @click="next('intrabank')"
            v-if="isSendMoneyIntraBankEnabled">
            <div class="details">
              <p class="label">
                {{ $t('accountType_intrabank') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2 border-on">
        <ul>
          <li
            @click="next('ach')"
            v-if="isSendMoneyACHEnabled">
            <div class="details">
              <p class="label">
                {{ $t('accountType_ach') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2 border-on">
        <ul>
          <li
            @click="next('check')"
            v-if="isSendMoneyCheckEnabled">
            <div class="details">
              <p class="label">
                {{ $t('enum_TransferType_check') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2 border-on">
        <ul>
          <li
            @click="next('domesticWire')"
            v-if="isSendMoneyDomesticwireEnabled">
            <div class="details">
              <p class="label">
                {{ $t('payment_domesticwire_title') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2 border-on">
        <ul>
          <li
            @click="next('cardPayment')"
            v-if="isSendMoneyVisaCardEnabled">
            <div class="details">
              <p class="label">
                {{ $t('accountType_card') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { getUIConfig } from '@/utils/env';
export default {
  data() {
    return {
      isSendMoneyIntraBankEnabled: getUIConfig('isSendMoneyIntraBankEnabled'),
      isSendMoneyACHEnabled: getUIConfig('isSendMoneyACHEnabled'),
      isSendMoneyCheckEnabled: getUIConfig('isSendMoneyCheckEnabled'),
      isSendMoneyDomesticwireEnabled: getUIConfig('isSendMoneyDomesticwireEnabled'),
      isSendMoneyVisaCardEnabled: getUIConfig('isSendMoneyVisaCardEnabled')
    }
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    intrabankText() {
      if (this.getSelectedContactData.intrabank) {
        const accountNumber =
          this.getSelectedContactData.intrabank.accountNumber;
        const maskedNo = accountNumber.slice(accountNumber.length - 4);
        return this.$t('contact_AccountNumber_title') + ' ***' + maskedNo;
      }
      return this.$t('accountType_paymentInfo');
    },
    achText() {
      if (this.getSelectedContactData.ach) {
        const accountNumber = this.getSelectedContactData.ach.accountNumber;
        const maskedNo = accountNumber.slice(accountNumber.length - 4);
        return this.$t('contact_AccountNumber_title') + ' ***' + maskedNo;
      }
      return this.$t('accountType_paymentInfo');
    },
    isFormValid() {
      return (
        this.getSelectedContactData &&
        (this.getSelectedContactData.ach ||
          this.getSelectedContactData.intrabank)
      );
    }
  },
  methods: {
    ...mapMutations('pay', ['updateSelectedPaymentType', 'setPayDetails']),
    cancel() {
      this.drawerBack();
    },
    next(type) {
      this.updateSelectedPaymentType(type);
      this.setPayDetails(null);
      switch (type) {
      case 'intrabank':
        this.drawerPush('intraBank');
        break;
      case 'ach':
        this.drawerPush('ach');
        break;
      case 'check':
        this.drawerPush('check');
        break;
      case 'domesticWire':
        this.drawerPush('domestic-wire-address');
        break;
      case 'cardPayment':
        this.drawerPush('card-payment');
        break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.contactAccountTypeForm {
  ul {
    margin-bottom: 15px;
  }

  .widgetContainer__body {
    padding-top: 32px;
  }
}
</style>
